
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private submitform!: any;
  private show = false;
  private fieldValue = "";
  private selectColumnsTemp = [];
  private secondColumnsTemp = [];
  private cascaderValue = "";
  private options = [
    { text: "通过", value: "1" },
    { text: "不通过", value: "0" },
  ];

  protected onFinish(select: any) {
    const { selectedOptions } = select;
    this.show = false;
    this.fieldValue = selectedOptions
      .map((option: any) => option.text)
      .join("");
    var mapOption = selectedOptions[0].value;
    this.submitform.approveResult = mapOption;
  }
}
