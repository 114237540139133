
import { Options, Vue } from "vue-property-decorator";
import selectperson from "@/views/app/safecheck/info/selectperson.vue";
import approveSelectshow from "./approveSelectshow.vue";
import * as api from "@/api/safecheck";
import File from "@/api/file/index.ts";

@Options({
  components: {
    selectperson,
    approveSelectshow,
  },
})
export default class extends Vue {
  private show = false;
  private previewimg = "";
  private canApp = "";
  private canRec = "";
  private director = "";
  private uploaderAfter: any[] = [];
  private infodata = {};
  private subform: any = {
    id: "",
    billId: "",
    speechCode: "",
    currentStatus: "",
    changePersonName: "",
    changePersonId: "",
    checkUserName: "",
    checkResult: "",
    checkUserId: "",
    pictureBefore: "",
    pictureAfter: "",
    currentPerson: "",
    approveResult: "",
    status: "",
    remark: "",
  };
  protected goBack() {
    this.$router.go(-1);
  }
  mounted() {
    this.initview();
  }

  protected previewimgfun(arg: any) {
    this.previewimg = arg;
    this.show = true;
  }

  initview() {
    api
      .mobileReportBillgetById({ billId: this.$route.query.id })
      .then((res: any) => {
        this.infodata = res.data;
        this.canApp = res.data.canApprove;
        this.canRec = res.data.canRectify;
        this.director = res.data.director;
        this.subform.currentStatus = res.data.status;
        this.subform.billId = res.data.billId;
        this.subform.speechCode = res.data.speechCode;
      });
  }

  protected onSubmit() {
    let enableSubmit = true;
    let arr = "";
    this.uploaderAfter.map((item) => {
      arr += "," + item.id;
    });
    this.subform.pictureAfter = arr.substr(1, arr.length - 1);
    this.subform.id = this.$route.query.id;
    if (this.subform.currentStatus === "0") {
      if (this.director === this.subform.currentPerson) {
        this.$notify({
          type: "danger",
          message: "审批人不能指定为整改负责人，请重新指定",
        });
        enableSubmit = false;
      } else {
        // 当前是待整改
        this.subform.status = "1";
        this.subform.remark = "整改完成";
      }
    } else if (this.subform.currentStatus === "1") {
      // 当前是待审批
      if (this.subform.approveResult === "0") {
        // 不通过
        this.subform.status = "0";
        this.subform.remark = "审批不通过";
        this.subform.currentPerson = this.director;
      } else {
        // 通过
        this.subform.status = "2";
        this.subform.remark = "审批通过";
      }
    }
    if (enableSubmit) {
      api
        .mobileReportRectifyUpdate({
          projectId: this.$store.state.project.projectinfo.projectId,
          ...this.subform,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.$toast.success({
              message: res.msg,
              forbidClick: true,
              onClose: () => {
                this.$router.go(-1);
              },
            });
          } else {
            this.$toast.fail(res.msg);
          }
        });
    }
  }

  checkUserNameselected(val: any) {
    this.subform.checkUserName = val.name;
    this.subform.currentPerson = val.id;
  }

  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile("reportBill/", file.file.name, file.file, true).then(
        (res: any) => {
          file.status = "done";
          file.id = res.data.id;
        }
      );
    });
  }
}
